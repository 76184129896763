.contact {
  padding-bottom: 5rem;
}

.contact .mail {
  color: var(--color-palet-4);
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.contact .mail:hover {
  transform: rotate(10deg) translateY(10px);
}

.contact .icons {
  display: flex;
  justify-content: space-around;
  margin-top: 6rem;
}

.contact .icons .icon {
  font-size: 5rem;
  visibility: hidden;
}

.contact .icons .iconLink {
  transition: all 0.2s;
}

.contact .icons a {
  color: white;
}

.contact .icons .iconLink:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.contact .icons .github {
  animation: moveUp 1s forwards 0s;
}

.contact .icons .linkedin {
  animation: moveUp 1s forwards 0.5s;
}

.contact .icons .envelope {
  animation: moveUp 1s forwards 1s;
}

.contact h1 span:first-child:hover {
  cursor: pointer;
}

@keyframes moveUp {
  from {
    transform: translateY(15rem);
    visibility: hidden;
    opacity: 0;
  }

  to {
    transform: translateY(0rem);
    visibility: visible;
    opacity: 1;
  }
}

/* Mobile */
@media (max-width: 650px) {
  .contact {
    overflow: hidden;
    padding-bottom: 7rem;
  }
  .icons {
    overflow: visible;
  }
}
