.headerBackground {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.headerImg {
  /* background-position: 0% 75%;
  background-repeat: no-repeat;
  background-size: cover; */
  pointer-events: none;
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: auto;
  bottom: -11vw;
}

.headerTitle {
  width: 100%;
  font-size: 3rem;
  text-align: center;
  padding: 5rem 0;
}

.headerTitle span {
  font-size: 2.5rem;
}

.headerTitle img {
  width: 2rem;
  height: 2rem;
}

.navbar {
  background-color: var(--color-palet-3);
  padding: 1px 0;
  z-index: 10;
}

.navbar ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10%;
}

.navbar ul li a {
  font-size: 1.3rem;
  color: var(--text-color);
  text-decoration: none;
}

@media (max-width: 700px) {
  .headerImg {
    height: 100%;
    bottom: 0;
  }
}

/* Mobile */
@media (max-width: 480px) {
  .navbar ul {
    gap: 0;
    justify-content: space-around;
  }

  .navbar ul li a {
    font-size: 1rem;
  }

  .headerTitle {
    padding: 4rem 0;
  }
}
