.tools {
  padding-bottom: 5rem;
}

.tools h1 {
  margin-bottom: 0;
  animation: toolsHeader 4s;
  /* font-size: 2rem; */
}

.toolsSections {
  display: flex;
  flex-direction: row;
  max-width: 70rem;
  align-items: center;
  min-height: 20rem;
}

.toolsSections section {
  width: 18rem;
  margin: 0 auto;
}

.toolsSections h2 {
  color: var(--color-palet-4);
}

.toolsSections .badges {
  position: relative;
  width: 100%;
  height: 8rem;
  margin: 0 auto;
}

.badge {
  width: fit-content;
  padding: 0.2rem 0.6rem;
  border-radius: 16px;
  font-size: 1.2rem;
  margin: 0.5rem;
  position: absolute;
  transition: all 0.3s;
}

.html5 {
  background-color: #e54c21;
  animation: htmlAnimation 2s ease-in-out;
  top: 0;
  left: 0;
}

.css3 {
  background-color: #2995ce;
  animation: cssAnimation 2s ease-in-out;
  top: 0;
  left: 32%;
}

.javascript {
  background-color: #f7e018;
  color: black;
  animation: javascriptAnimation 1.5s ease-in-out;
  top: 0;
  left: 59%;
}

.react {
  background-color: var(--color-palet-4);
  animation: reactAnimation 1s ease-in-out;
  top: 35%;
  left: 5%;
}

.reactNative {
  background-color: var(--color-palet-4);
  animation: reactNativeAnimation 2s ease-in-out;
  top: 35%;
  left: 32%;
}

.vue {
  background-color: #79cda7;
  animation: vueAnimation 2s ease-in-out;
  top: 35%;
  left: 80%;
}

.php {
  background-color: #767bb3;
  animation: phpAnimation 3s ease-in-out;
  top: 0;
  left: 5%;
}

.nodejs {
  background-color: #90c53f;
  animation: nodejsAnimation 1.5s ease-in-out;
  top: 0;
  left: 29%;
}

.mysql {
  background-color: #f49823;
  animation: mysqlAnimation 2s ease-in-out;
  top: 0%;
  left: 60%;
}

.mongodb {
  background-color: #4ca84a;
  animation: mongodbAnimation 2s ease-in-out;
  top: 35%;
  left: 7%;
}

.api {
  background-color: #e6f3fe;
  color: black;
  animation: apiAnimation 2s ease-in-out;
  top: 35%;
  left: 48%;
}

.html5:hover,
.javascript:hover,
.reactNative:hover,
.nodejs:hover,
.mongodb:hover {
  transform: rotate(10deg) translateX(5px);
}

.css3:hover,
.react:hover,
.php:hover,
.mysql:hover,
.api:hover,
.vue:hover {
  transform: rotate(-10deg) translateY(-5px);
}

.works {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 8%;
  padding-bottom: 3rem;
  flex-wrap: wrap;
}

.works img {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  visibility: hidden;
  transition: all 0.3s;
}

.works img:hover {
  transform: scale(1.2);
}

.work .animate img {
  animation: rollIn 2s ease-out;
  visibility: visible;
}

.works img:hover {
  cursor: pointer;
}

/* Animations */
@keyframes htmlAnimation {
  from {
    left: -20%;
    top: 0;
  }

  to {
    left: 0;
    top: 0;
  }
}

@keyframes cssAnimation {
  from {
    left: -20%;
    top: -250%;
  }

  to {
    left: 32%;
    top: 0;
  }
}

@keyframes javascriptAnimation {
  from {
    left: 80%;
    top: -70%;
  }

  to {
    left: 59%;
    top: 0;
  }
}

@keyframes reactAnimation {
  from {
    left: 30%;
    top: 230%;
  }

  to {
    left: 5%;
    top: 35%;
  }
}

@keyframes reactNativeAnimation {
  from {
    left: 80%;
    top: 150%;
    transform: rotate(0deg);
  }

  to {
    left: 32%;
    top: 35%;
    transform: rotate(360deg);
  }
}

@keyframes vueAnimation {
  from {
    left: 110%;
    top: 35%;
  }

  to {
    left: 80%;
    top: 35%;
  }
}

@keyframes phpAnimation {
  from {
    left: 100%;
    top: 300%;
  }

  to {
    left: 5%;
    top: 0;
  }
}

@keyframes nodejsAnimation {
  from {
    left: 0;
    top: 300%;
  }

  to {
    left: 29%;
    top: 0;
  }
}

@keyframes mysqlAnimation {
  from {
    left: 56%;
    top: 300%;
  }

  to {
    left: 60%;
    top: 0%;
  }
}

@keyframes mongodbAnimation {
  from {
    left: 27%;
    top: 180%;
  }

  to {
    left: 7%;
    top: 35%;
  }
}

@keyframes apiAnimation {
  from {
    left: 48%;
    top: 150%;
  }

  to {
    left: 48%;
    top: 35%;
  }
}

@keyframes toolsHeader {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rollIn {
  from {
    transform: translateX(-50rem) rotate(0deg);
  }
  to {
    transform: translateX(0rem) rotate(360deg);
  }
}

/* Mobile */
@media (max-width: 650px) {
  .tools {
    overflow: hidden;
  }
  .toolsSections {
    flex-direction: column;
  }
  .toolsSections h2 {
    font-size: 1.2rem;
  }

  .tools h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
