.home {
  animation: fadeInHome 0.5s;
  margin-top: 3rem;
}

.me {
  width: 20rem;
  margin: 0 auto;
  border-radius: 16px;
  opacity: 1;
  transition: opacity 500ms ease 0s;
  height: auto;
}

.info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5%;
}

.info .text {
  width: 25rem;
}

.img p {
  text-align: center;
}

@keyframes fadeInHome {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}
