.experience {
  margin-top: 0;
}

.experience section {
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
}

.experience section div {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.experience section img {
  height: 8rem;
  width: 8rem;
  object-fit: contain;
  border-radius: 50%;
}

.experience section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: left;
}

.experience section p {
  margin: 0;
}

.experience section a {
  color: var(--text-color);
  text-decoration: none;
}

.experience section i {
  margin-bottom: 0.5rem;
}

#hiperBrand {
  background-color: #303A4B;

}

.hiper {
  animation: fadeIn 3s 0s forwards;
  opacity: 0;
}

.tales {
  animation: fadeIn 3s 0.5s forwards;
  opacity: 0;
}

.holder100 {
  animation: fadeIn 3s 1s forwards;
  opacity: 0;
}

.supeo {
  animation: fadeIn 3s 1.5s forwards;
  opacity: 0;
}

.webDevelopment {
  animation: fadeIn 3s 0s forwards;
  opacity: 0;
}

.computerScience {
  animation: fadeIn 3s 0.5s forwards;
  opacity: 0;
}

.workIcon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.atZealand {
  text-align: left !important;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .experience section img {
    width: 4rem;
    height: 4rem;
  }

  .experience .description {
    margin-right: 0.9rem;
  }
}