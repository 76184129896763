:root {
  --color-palet-1: #27496d;
  --color-palet-2: #142850;
  --color-palet-3: #0c7b93;
  --color-palet-4: #00a8cc;
  --text-color: #fff;
  --max-width: 50rem;
}

/* @import url("assets/css/scrollbar.css"); */
@import url("assets/css/switch.css");

@font-face {
  font-family: Nunito;
  src: url("assets/font/Nunito-VariableFont_wght.ttf");
}

html {
  min-height: 100%;
  overflow-y: overlay;
  scrollbar-width: none; /* Firefox */
}

body {
  background-color: var(
    --color-palet-1
  ); /* For browsers without gradient support (aka. internet explorer) */
  background-image: linear-gradient(var(--color-palet-1), var(--color-palet-2));
  color: var(--text-color);
  min-height: 100%;
  margin: 0;
  font-family: Nunito;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.container {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  margin: 1.5rem auto 0 auto;
  padding: 0 1rem;
}

.container h1 {
  text-align: center;
  font-size: 2.5rem;
}

.container h2,
.container h3 {
  text-align: center;
}

.container p {
  font-size: 1.2rem;
  text-align: justify;
  overflow-wrap: break-word;
}
